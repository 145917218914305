<template v-if="item.question_type_id == 1">
  <div class="d-flex">
    <likert
      :values="item.response_summary ? item.response_summary.agreement : []"
      :average="item.ai_agreement"
      type="agreement"
    />
  </div>
</template>

<script>
export default {
  props: ["item"],
};
</script>
