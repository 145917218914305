import { render, staticRenderFns } from "./DetailsAHistogram.vue?vue&type=template&id=482a3a3a&v-if=item.question_type_id%20%3D%3D%201&"
import script from "./DetailsAHistogram.vue?vue&type=script&lang=js&"
export * from "./DetailsAHistogram.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports